<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        
        <v-data-table
            v-if="!api.isLoading && data!=null"
            :items="data"
            :headers="dataHeader"
            class="elevation-1 mt-4">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:item.status="{item}">
                <span>
                    {{ (item.status==1)?"Enable":"Disabled" }}
                </span>
            </template>
            <template
                v-slot:item.beta="{item}">
                <span>
                    {{ (item.beta==1)?"Enable":"Disabled" }}
                </span>
            </template>
            <template
                v-slot:item.features="{item}">
                <router-link
                    :to="{name:'PageFeaturesDetail',params:{id:item.id}}">
                    {{ item.features }}
                </router-link>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Features',
                value:'features',
            },
            {
                text:'Status',
                value:'status',
            },
            {
                text:'Testing',
                value:'beta',
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getListOfFeatures"){
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchListOfFeatures());
        },
        fetchListOfFeatures() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/features";
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>